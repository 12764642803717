/**
 * Collections
 */
export enum FirebaseCollections {
    PRODUCT_DETAILS = 'product_details',
    PRODUCT_SEGMENTS = 'SEGMENT_Snapshots',
    PRODUCT_EXTERNAL_REVIEWS = 'PRODUCT_ExternalReviews',
    PRODUCT_REVIEWS = 'PRODUCT_Reviews',
    PRODUCT_HISTORY = 'PRODUCT_History',
    PRODUCT_BANNER = 'CONTENT_Product-Banners',
    CONTRIBUTORS = 'CONTENT_Contributors',
    ARTICLES = 'CONTENT_Articles',
    PAGES = 'CONTENT_Pages',
    LISTS = 'CONTENT_Lists',
    PAGE_SEGMENT = 'CONTENT_Page_Segment',
    NAVIGATION = 'CONTENT_Navigation'
}

/**
 * SSR: Transfer state configuration
 */
export enum ContentArticlesTransferStateKeys {
    ONE = 'firebase:content-articles:one',
    LIST = 'firebase:content-articles:list'
}

export enum ContentPagesTransferStateKeys {
    ONE = 'firebase:content-pages:one',
    LIST = 'firebase:content-pages:list'
}

export enum ContentListsTransferStateKeys {
    ONE = 'firebase:content-lists:one',
    LIST = 'firebase:content-lists:list'
}

export enum InventoryProductsTransferStateKeys {
    ONE = 'firebase:inventory-products:one',
    LIST = 'firebase:inventory-products:list'
}

export enum InventorySuppliersTransferStateKeys {
    ONE = 'firebase:inventory-suppliers:one',
    LIST = 'firebase:inventory-suppliers:list'
}
